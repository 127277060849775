import React from 'react';
import ritual from "../../../assets/images/store/ritual.png";
import ritualmobile from "../../../assets/images/store/ritualmobile.png";
import "./Discover.scss";

export default function Discover() {
  return (
    <div>
      <div className="ritual mt-3">
        <h2 style={{ fontFamily: "Corona", color: "#0c243f"}}>descubrí el ritual</h2>
        <p className="mb-5" >Ninguna Corona está completa sin el ritual de la lima. Disfrutá del sabor y frescura en forma natural.<br/>Viví la experiencia Corona desde donde estés.</p>
        <img src={ritual} alt="img" className="d-none d-md-block mx-auto" />
        <img src={ritualmobile} alt="img" className="d-flex  mx-auto d-md-none" fluid />
      </div>
    </div>
  )
}
