import React, { useState, useEffect } from "react"
import SEO from "../../components/seo"


import NavbarStore from "../../components/Store/NavbarStore"
import OurBeers from "../../components/Store/OurBeers"
import Banner from "../../components/Store/Banner"
import Inspiration from "../../components/Store/Inspiration"
import Discover from "../../components/Store/Discover"
import AgeGate2 from "../../components/agegate"
import FooterSmall from "../../components/FooterStore"
import { Store } from "../../providers/StoreProvider"

import heroImage from "../../../assets/images/store/Asset 1 1.png"
import heroImageSmall from "../../../assets/images/store/Asset 1 2.png"
import FacebookIcon from "../../../assets/images/social/face.png"
import InstagramIcon from "../../../assets/images/social/insta.png"
import Linea from "../../../assets/images/social/line.png"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import { Link, graphql } from "gatsby"
import "../../styles/corona-store.scss"

const CoronaStore = ({ data }) => {
  const [age, setAge] = useState(true),
    [slideItems, setSlideItems] = useState([]),
    [discovery, setDiscovery] = useState([]),
    [banners, setBanners] = useState([]),
    [beers, setBeers] = useState([]),
    { width } = useWindowDimensions()

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
  }, [])

  useEffect(() => {
    if (data) {
      // setSlideItems(data.allStore.nodes.map(i => i));
      setSlideItems([{button: "Conocela acá.",
      id: "Nl1mzRV3B1MPGTM2VP23",
      img: "https://firebasestorage.googleapis.com/v0/b/casacorona.appspot.com/o/img_store%2F1645107314150?alt=media&token=dee78beb-302b-48b3-bb44-fe0e776a86b2",
      img_mobile: "https://firebasestorage.googleapis.com/v0/b/casacorona.appspot.com/o/img_store%2F1645107286360?alt=media&token=cdf839f1-cb73-4c1c-9c2c-77ed156adbe5",
      index: 0,
      text: "Conseguí nuestras gorras hechas con plástico reciclado.",
      title: "Una colección de gorras, 9.000 botellas menos en el mar."}])
      setDiscovery(data.allDiscovery.nodes.map(i => i));
      setBanners(data.allBanners.nodes.map(i => i));
      setBeers(data.allItems.nodes.map(i => i));
    }
  }, [data])
  return !age ? (
    <div className="store">
      <SEO title="Corona Store" />
      <div className="store-hero">
        <NavbarStore width={width} home />
        {slideItems.map(item => (
          <div className="store-hero_background" key={item.id}>
            {
              width <= 500 ?
              <img src={item.img_mobile} alt="hero" className="heroImage" /> :
              <img src={item.img} alt="hero" className="heroImage" />
            }
            <div className="shade" />
            <div className="row">
              <div className="col-1 col-md-1" />
              <div className="store-hero_content d-flex justify-content-center align-items-center align-items-md-start flex-column col-10 col-md-6 ">
                <h4 style={{ fontFamily: 'Corona' }}>{item.title}</h4>
                <p style={{ fontFamily: 'Corona' }}>{item.text}</p>
                <Link
                  className="buybutton text-decoration-none"
                  direction="up"
                  duration="0.5"
                  bg="#ffc311"
                  href="/corona-store/productos"
                >
                  {item.button}
                </Link>
              </div>
            </div>
            <div className="col-1 col-md-6" />
            <div className="store-hero_socials d-none d-md-flex flex-column justify-content-center align-items-center">
              <a href="https://www.instagram.com/corona_argentina" target="_blank">
                <img
                  className="soc"
                  src={InstagramIcon}
                  alt="Instagram"
                  style={{ width: " 1rem", height: "1rem" }}
                />
              </a>
              <a href="https://www.facebook.com/cervezacorona" target="_blank">
                <img
                  className="soc"
                  src={FacebookIcon}
                  alt="Facebook"
                  style={{ width: "0.5rem", height: "1rem" }}
                />
              </a>

              <img
                src={Linea}
                alt="linea"
                style={{ width: "1.5px", height: "3rem" }}
              />
            </div>
          </div>
        ))}
      </div>
      {/* <div className="store-hero_footer d-none d-md-flex justify-content-around align-items-center">
        <p>ingredientes 100% naturales</p>
        <p>#this is living</p>
        <p>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} /> +3000 opiniones
        </p>
      </div> */}
      <Store.Consumer>
        {context =>
          <OurBeers beers={beers} context={context} />
        }
      </Store.Consumer>
      <Banner width={width} banners={banners} />
      <Inspiration discovery={discovery} />
      <Discover />
      <FooterSmall />
      {/* {width <= 500 ? (
        <FooterSmall styles={{ paddingTop: "150px" }} />
      ) : (
        <FooterStore />
      )} */}
    </div>
  ) : (
    <AgeGate2 path="/corona-store" />
  )
}

export const query = graphql`
  query store {
    allStore(sort: { fields: index }) {
      nodes {
        id
        index
        img
        img_mobile
        title
        text
        button
      }
    }
    allItems(sort: { fields: index }) {
      nodes {
        id
        index
        img
        img_mobile
        title
        product
      }
    }
    allDiscovery(sort: { fields: index }) {
      nodes {
        id
        index
        img
        text
        button
      }
    }
    allBanners(sort: { fields: index }) {
      nodes {
        id
        index
        img
        img_mobile
      }
    }
  }
`

export default CoronaStore
