import React from 'react'
import {Carousel} from "react-bootstrap"
import image from "../../../assets/images/store/banner.png"
import imagemobile from "../../../assets/images/store/bannermobile.png"
import './Banner.scss'

export default function Banner({ banners, width }) {
  const handleDragStart = (e) => e.preventDefault();


  return (
    <div className="container-fluid bannernewproducts">
      <div className="text-center ">
        <Carousel>
          {banners.map(item => {
            return (
              <Carousel.Item>
                <img 
                  className="img"
                  key={item.id}
                  src={width > 500 ? item.img : item.img_mobile}
                  onDragStart={handleDragStart}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
      </div>
    </div>
  )
};
