import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import arrow from "../../../assets/images/store/Arrow2.png";
import { Carousel } from "react-bootstrap";
import "./Inspiration.scss";

const categories = [ "Indumentaria", "Combos", "Colabs", "Accesorios"];

function InspirationCard({ img, text, cat, button }) {
  return (
    <div className="inspirationcard ">
      <div className="inspirationcard-img">
        <img src={img} alt="img" className="img" />
        <p className="inspirationcard-highlighttext">
          <span className="backcat">{cat}</span>
        </p>
      </div>
      <p className="text-left inspirationcard-text">{text}</p>
      <div className="d-flex justify-content-start align-items-center mt-2">
        <a
          className="smalldiscoverbutton"
          direction="up"
          duration="0.5"
          bg="#ffc311"
          href={button}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver más
          <img
            src={arrow}
            alt="->"
            width="15px"
            style={{
              position: "relative",
              margin: "0",
              marginLeft: "10px",
              fontSize: "bold",
            }}
          />
        </a>
      </div>
    </div>
  )
}

export default function Inspiration({ discovery }) {
  const { width } = useWindowDimensions();

  return (
    <div className="my-5 inspiration">
      <div className="title">
        <h2 style={{ fontFamily: "Corona", color: "#0c243f" }}>
          Combos, colabs y accesorios
        </h2>
        <p>
          Explorá nuestro merch, indumentaria y accesorios de edición limitada.
        </p>
      </div>
      {width >= 768 ? (
        <div
          style={{ width: "95%" }}
          className="row mt-5 mx-auto d-flex justify-content-center align-items-center"
        >
          {discovery.map((item, i) => {
            return <InspirationCard {...item} cat = {categories[i]}  />
          })}
        </div>
      ) : (
        <div className="inspirationcarousel">
          <Carousel>
            {discovery.map((item, i) => {
              return (
                <Carousel.Item>
                  <InspirationCard {...item} cat = {categories[i]} />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      )}
    </div>
  );
}
