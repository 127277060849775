export default function GetBeersLinks(setLoading, setOptions) {
  let requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  // https://dev-services.craftsociety.com.ar/v2/products/corona
  fetch("https://services.craftsociety.com.ar/v2/products/corona", requestOptions)
    .then(response => response.json())
    .then(result => {
      setOptions(result.collections.find(i => i.title === 'Cerveza Corona')['products'].map(p =>  { return {
        link:p.url,
        price:p.variants[0].price,
        description:p.description,
        name:p.name,
        variant_id:p.variants[0].variant_id,
        variantId: p.variants[0].id
      }}));
      setLoading(false);
    }).catch(error => console.log('error', error));
}


// setOptions(result.collections.find(i => i.title === 'Cerveza Corona')['products'].map(p => {
//   return p.variants.map((v,idx)=>{
//     return(
//       {...p,size:idx,price:v.price,variantId:v.id,variant_id:v.id}
//     )
//   })
// }));