import React, { useEffect, useRef, useState } from "react";
import '../styles/welcome.css';
import logo from "../../assets/images/logos/logo_color.png";
import Div100vh from 'react-div-100vh';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../components/seo"

function _calculateAge(birthday) { // birthday is a date
  let ageDate = new Date(Date.now() - birthday.getTime()); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const  AgeGate2 = ({path,setAge}) => {
  let [form, setForm] = useState({dd: '', mm:'', aaaa: ''}),
  [old, setOld] = useState(false),
  inputDay = useRef(),
  inputMonth = useRef(),
  inputYear = useRef();

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "dd") {
      if (value.length >= 2) {
        value = value.slice(0, 2);
        inputMonth.current.focus();
      }
    }
    else if (e.target.name === "mm") {
      if (value.length >= 2) {
        value = value.slice(0, 2);
        inputYear.current.focus();
      }
      else if (value.length === 0) {
        inputDay.current.focus();
      }
    }
    else {
      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      else if (value.length === 0) {
        inputMonth.current.focus();
      }
    }
    
    setForm({ ...form, [e.target.name]: value });
  }

  useEffect(() => {
    if (form.aaaa.length === 4 && form.dd && form.mm) {
      let birthday = new Date();
      birthday.setDate(form.dd)
      birthday.setMonth(parseInt(form.mm) - 1);
      birthday.setFullYear(form.aaaa);
      setOld(_calculateAge(birthday) >= 18);
      // console.log(_calculateAge(birthday) >= 18)
      if(_calculateAge(birthday) >= 18)sessionStorage.setItem('age', true);
      if(form.dd && form.mm && form.aaaa){
        window.dataLayer.push({
          'event' : 'GAEvent',
          'event_category': 'Age Gate',
          'event_action':'Interaction',
          'event_label': 'Yes',
          'interaction': 'false',
          'component_name': 'full_age_gate',
          'element_text': 'Full Age gate',
        });
      }
      
    }
  }, [form]);

  const handleConfirm = ()=>{
    window.dataLayer.push({
      'event' : 'GAEvent',
      'event_category': 'Age Gate',
      'event_action':'Interaction',
      'event_label': 'Send',
      'interaction': 'false',
      'component_name': 'Botón_ver_más',
      'element_text': 'Ver Más',
    });
    if(old && setAge){
      setAge(false)
      window.dataLayer.push({
        'event' : 'GAEvent',
        'event_category': 'Age Gate',
        'event_action':'Interaction',
        'event_label': 'Yes',
        'interaction': 'false',
        'component_name': 'Botón_ver_más',
        'element_text': 'Ver Más',
      });
    } else if(!old){
      window.dataLayer.push({
        'event' : 'GAEvent',
        'event_category': 'Age Gate',
        'event_action':'Interaction',
        'event_label': 'No',
        'interaction': 'false',
        'component_name': 'full_age_gate',
        'element_text': 'Full Age gate',
      });
    }


  }
  return (
    // <LayoutConfirmation>
    //   <SEO title="index" />
    //   {/* <Confirmation/> */}

    // </LayoutConfirmation>
    <Div100vh>
      <SEO title="Age Gate" />
      {/* logo */}
      <div className="row">
        <div className="col welcomeContentLogo">
          <img src={logo} alt="logo" className="welcomeLogo"/>
        </div>
      </div>

      {/* info */}
      <div className="row welcomeContentInfo">
        <div className="col">

          {/* title */}
          <div className="row">
            <div className="col">
              <h1 className="welcomeTitle">Bienvenido</h1>
              <h3 className="welcomeSubtitle">Para continuar, ingresá tu fecha de nacimiento</h3>
            </div>
          </div>

          {/* date */}
          <div className="row">
            <div className="col">
              <form action="">
                <div className="welcomeContentInputs">
                  <input maxLength="2" type='number' name={'dd'} onChange={onChange} className="welcomeInput" placeholder="DD" value={form.dd} ref={inputDay} />
                  <p className="welcomeInputSeparator">|</p>
                  <input maxLength="2" type='number' name={'mm'} onChange={onChange} className="welcomeInput" placeholder="MM" value={form.mm} ref={inputMonth} />
                  <p className="welcomeInputSeparator">|</p>
                  <input maxLength="4" type='number' name={'aaaa'} onChange={onChange} className="welcomeInput" placeholder="AAAA" value={form.aaaa} ref={inputYear} />
                </div>
                <div onClick={handleConfirm}>
                <AniLink to={old ? (path ? path: null) : 'http://www.vivamosresponsablemente.com'} cover direction="down" duration={.5} bg="#ffc311" className="welcomeButton">
                    aceptar
                </AniLink>
                </div>
              </form>
            </div>
          </div>

          {/* terms and conditions */}
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <p className="welcomeParagraph">No se quede conectado si comparte el dispositivo con menores. Usamos cookies para mejorar nuestros servicios.
              Al continuar la navegación está aceptando los términos de uso, las políticas de privacidad y el uso de las cookies</p>
              <div className="row d-flex justify-content-center">
                <a target="_blank" download href="/TyCCervezaCorona.pdf" className="col-4 welcomeLinksTerms text-right">Términos de uso</a>
                <div className="col-1 welcomeLinksSeparator">|</div>
                <a target="_blank" download  href="/ppdp.pdf" className="col-4 welcomeLinksTerms text-left">Políticas de privacidad</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    
    </Div100vh>

  )

}

export default AgeGate2

