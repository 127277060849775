import React, { useState, useEffect } from "react";
import back from "../../../assets/images/store/backtexturado.png";
import "react-alice-carousel/lib/alice-carousel.css";
import GetBeersLinks from "./Products/GetBeersLinks";
import AliceCarousel from "react-alice-carousel";
import "./OurBeers.scss";
import { useProductos } from "../../hooks/useProductos";
import spinner from "../../../assets/images/store/spinner.svg"
export default function OurBeers({ beers, context }) {

  const [productosPrincipales, setProductosPrincipales] = useState([])
  const [products, setProducts] = useState([]),
  { productos } = useProductos(),
  handleDragStart = e => e.preventDefault(),
  [loading, setLoading] = useState(true),
  [items, setItems] = useState([]),
  responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  // useEffect(()=>{
  //   if(beers.length){
  //     console.log("Productos!",productos)
  //   }
  // },[beers])

  useEffect(() => GetBeersLinks(setLoading, setProducts), []);

  useEffect(() => {
    if (!loading && productos.length) {
      let data = []
      beers.map((beer)=>{
        productos[0].products.map((p)=>{
            p.variants.map((variant,idx)=>{
                if(variant.id === beer.product) return data.push({...p,match: idx,product:beer.product,id: beer.id, img: beer.img, img_mobile: beer.img_mobile, title: beer.title})
            })
        })
      })
      setProductosPrincipales(data)
      setItems(
        data.map(b => (
            <div key={b.variant_id} className="col-md-2 col-lg-12 mr-5" onDragStart={handleDragStart}>
              <img src={b.img} alt="beer" className="imgbeer" />
              <h5>{b.title}</h5>
              {/* <h5 className="price">${b.price}</h5> */}
              <button
                className="buybutton"
                direction="up"
                duration="0.5"
                bg="#ffc311"
                onClick={() => context.setDetalleProducto(b,b.match)}
                alt="_blank"
              >
                Comprar Ahora
              </button>
            </div>
          )
        )
      );
    }
  }, [loading,productos]);

  return (
    <div className="container-fluid mx-0 px-0 mt-5 ourbeers">
      <div className="ourbeers-content">
        <img src={back} alt="" className="ourbeers-content-back" />
        <div className="title">
          <h2 style={{ fontFamily: "Corona" }} className="mb-4">
            conocé Nuestras <br className="d-md-none" />
            <span className="spancerveza ml-2"> cervezas</span>
          </h2>
          <p className="">
              Elegí una de nuestras variedades para disfrutar bien frías. O <br />
              porqué no dos
          </p>
          {!productosPrincipales.length && <div style={{ minHeight: "200px", margin: "2rem auto" }}>
            <img src={spinner} alt="Cargando.." />
          </div> }
        </div>
          <AliceCarousel
            mouseTracking
            animationType="slide"
            autoPlayInterval="2000"
            infinite
            disableDotsControls
            autoPlay
            responsive={responsive}
            items={items}
          />
      </div>
    </div>
  )
}